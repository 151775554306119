/**
 * Converts a URL to its non-SEO search results page version by adding the search parameter.
 * This helps distinguish between SEO and non-SEO SRP.
 *
 * @param url - URL or string to be converted
 * @returns string - The modified URL as a string
 */
export const toNonSeoSrpUrl = (url: URL | string, categoryId: number): string => {
  const urlObject = typeof url === 'string' ? new URL(url) : url

  // Always add search parameter to mark as non-SEO only if URL has valid category
  if (categoryId) {
    urlObject.searchParams.set('search', 'true')
  }

  return urlObject.toString()
}
